/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {DataList} from '@servicetitan/design-system';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong",
    pre: "pre",
    code: "code",
    h1: "h1",
    div: "div",
    h2: "h2",
    ul: "ul",
    li: "li",
    h3: "h3",
    b: "b",
    hr: "hr"
  }, _provideComponents(), props.components);
  if (!DataList) _missingMdxReference("DataList", false);
  if (!DataList.HeaderCell) _missingMdxReference("DataList.HeaderCell", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Commonly, Sidebars will contain a ", React.createElement(_components.a, {
    href: "/components/side-nav"
  }, React.createElement(_components.strong, null, "SideNav")), " but can also contain other content when necessary."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "scaled: true\n---\n<State initial={0}>\n\t{([active, setActive]) => (\n\t\t<Frame>\n\t\t\t<Page\n\t\t\t\tsidebar={\n\t\t\t\t\t<Sidebar localStorageKey=\"sidebar__default\">\n\t\t\t\t\t\t<Sidebar.Section padding=\"y\">\n\t\t\t\t\t\t\t<SideNav title=\"Marketing\">\n\t\t\t\t\t\t\t\t<SideNav.Item onClick={() => setActive(0)} active={active===0}>Dashboard</SideNav.Item>\n\t\t\t\t\t\t\t\t<SideNav.Item onClick={() => setActive(1)} active={active===1}>Campaigns</SideNav.Item>\n\t\t\t\t\t\t\t\t<SideNav.Item onClick={() => setActive(2)} active={active===2}>Emails</SideNav.Item>\n\t\t\t\t\t\t\t\t<SideNav.Item onClick={() => setActive(3)} active={active===3}>Audiences</SideNav.Item>\n\t\t\t\t\t\t\t</SideNav>\n\t\t\t\t\t\t</Sidebar.Section>\n\t\t\t\t\t\t<Sidebar.Section>\n\t\t\t\t\t\t\t<Card onClick={() => setActive(4)} active={active===4}>Test</Card>\n\t\t\t\t\t\t</Sidebar.Section>\n\t\t\t\t\t</Sidebar>\n\t\t\t\t}\n\t\t\t>\n\t\t\t\tPage Content\n\t\t\t</Page>\n\t\t</Frame>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h1, {
    id: "behavior",
    style: {
      position: "relative"
    }
  }, "Behavior", React.createElement(_components.a, {
    href: "#behavior",
    "aria-label": "behavior permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Sidebars are responsive and will collapse if it is within a browser window smaller than 992px. The biggest difference in behavior is between large displays and small displays. On large displays the open/close state is stored in the browser's ", React.createElement(_components.code, null, "localStorage"), ". On small displays, the Sidebar is always in a ", React.createElement(_components.strong, null, "temporary state"), " and it's open/close state is not saved."), "\n", React.createElement(_components.h2, {
    id: "temporary-state",
    style: {
      position: "relative"
    }
  }, "Temporary State", React.createElement(_components.a, {
    href: "#temporary-state",
    "aria-label": "temporary state permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "When the Sidebar is in a temporary state, interacting outside of the Sidebar (hovering or tapping outside the Sidebar) while open will close it."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "On small displays, Sidebars are always in a temporary state"), "\n", React.createElement(_components.li, null, "On large displays, Sidebars temporarily open only if they are hovered over or tapped on while collapsed, clicking/tapping the expand button for large displays will remove the temporary state and keep the Sidebar open"), "\n", React.createElement(_components.li, null, "In a temporary state, Sidebars hover over content when open instead of taking up space and pushing content over"), "\n"), "\n", React.createElement(_components.h2, {
    id: "remembers-previous-openclose-state",
    style: {
      position: "relative"
    }
  }, "Remembers previous open/close state", React.createElement(_components.a, {
    href: "#remembers-previous-openclose-state",
    "aria-label": "remembers previous openclose state permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The Sidebar remembers and restores the previous non-temporary open/close state across user sessions and window resizes. Since small displays are always temporary, only the state of large displays are saved. Each Sidebar instance remembers its own state as long as a static unique ", React.createElement(_components.code, null, "localStorageKey"), " prop is given."), "\n", React.createElement(_components.h2, {
    id: "interactions",
    style: {
      position: "relative"
    }
  }, "Interactions", React.createElement(_components.a, {
    href: "#interactions",
    "aria-label": "interactions permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h3, {
    id: "large-displays",
    style: {
      position: "relative"
    }
  }, "Large Displays", React.createElement(_components.a, {
    href: "#large-displays",
    "aria-label": "large displays permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Large displays are defined as having a browser window width greater than or equal to 992px."), "\n", React.createElement(DataList, {
    className: "DocsTable",
    header: [React.createElement(DataList.HeaderCell, {
      key: 0,
      name: "a",
      content: ""
    }), React.createElement(DataList.HeaderCell, {
      key: 1,
      name: "b",
      content: "Toggle"
    }), React.createElement(DataList.HeaderCell, {
      key: 2,
      name: "c",
      content: "Sidebar"
    }), React.createElement(DataList.HeaderCell, {
      key: 3,
      name: "d",
      content: "Outside Sidebar"
    })],
    items: [{
      a: React.createElement(_components.b, null, "Click"),
      b: 'open/close',
      c: 'n/a',
      d: 'n/a'
    }, {
      a: React.createElement(_components.b, null, "Touch/Tap"),
      b: 'open/close',
      c: 'n/a',
      d: 'n/a'
    }, {
      a: React.createElement(_components.b, null, "Hover"),
      b: 'show tooltip',
      c: 'if collapsed, temporarily open',
      d: 'if temporarily open, close'
    }]
  }), "\n", React.createElement(_components.h3, {
    id: "small-displays",
    style: {
      position: "relative"
    }
  }, "Small Displays", React.createElement(_components.a, {
    href: "#small-displays",
    "aria-label": "small displays permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Small displays are defined as having a browser window width less than 992px and are always in a temporary state."), "\n", React.createElement(DataList, {
    className: "DocsTable",
    header: [React.createElement(DataList.HeaderCell, {
      key: 0,
      name: "a",
      content: ""
    }), React.createElement(DataList.HeaderCell, {
      key: 1,
      name: "b",
      content: "Toggle"
    }), React.createElement(DataList.HeaderCell, {
      key: 2,
      name: "c",
      content: "Sidebar"
    }), React.createElement(DataList.HeaderCell, {
      key: 3,
      name: "d",
      content: "Outside Sidebar"
    })],
    items: [{
      a: React.createElement(_components.b, null, "Click"),
      b: 'temporarily open/close',
      c: 'n/a',
      d: 'n/a'
    }, {
      a: React.createElement(_components.b, null, "Touch/Tap"),
      b: 'temporarily open/close',
      c: 'temporarily open',
      d: 'close'
    }, {
      a: React.createElement(_components.b, null, "Hover"),
      b: 'show tooltip',
      c: 'temporarily open',
      d: 'close'
    }]
  }), "\n", React.createElement(_components.h1, {
    id: "sidebar-sections",
    style: {
      position: "relative"
    }
  }, "Sidebar Sections", React.createElement(_components.a, {
    href: "#sidebar-sections",
    "aria-label": "sidebar sections permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Sidebar Sections are used as direct children of the Sidebar to section off the content with a horizontal divider"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "scaled: true\n---\n<State initial={0}>\n\t{([active, setActive]) => (\n\t\t<Sidebar localStorageKey=\"sidebar__sidebar-sections\">\n\t\t\t<Sidebar.Section>\n\t\t\t\tSection 1\n\t\t\t</Sidebar.Section>\n\t\t\t<Sidebar.Section>\n\t\t\t\tSection 2\n\t\t\t</Sidebar.Section>\n\t\t\t<Sidebar.Section>\n\t\t\t\tSection 3\n\t\t\t</Sidebar.Section>\n\t\t</Sidebar>\n\t)}\n</State>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "best-practices",
    style: {
      position: "relative"
    }
  }, "Best practices", React.createElement(_components.a, {
    href: "#best-practices",
    "aria-label": "best practices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Sidebars should be within a ", React.createElement(_components.a, {
    href: "/components/page/"
  }, React.createElement(_components.strong, null, "Page"))), "\n", React.createElement(_components.li, null, "Sidebars should be given a static unique localStorageKey prop value"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
